<template>
  <div class="home">
    <a-input-search
        class="input"
        v-model:value="value"
        placeholder="请输入要缩短的网站地址"
        button-text="立刻缩短"
        search-button
        @search="Open"
    />
    <br/>
    <a-radio-group class="group" v-model:model-value='form.id'>
      <a-radio value="l.lidabai.icu">l.lidabai.icu</a-radio>
      <a-radio value="x.lidabai.icu">x.lidabai.icu</a-radio>
      <a-radio value="b.lidabai.icu">b.lidabai.icu</a-radio>
    </a-radio-group>
    <a-modal v-model:visible="visible" title="创建短连接" @cancel="handleCancel" @ok="handleBeforeOk">
    <a-form :model="form">
      <a-form-item field="key" label="关键词">
        <a-input v-model="form.key" />
      </a-form-item>
    </a-form>
  </a-modal>
  <div class="url">
    <a-modal v-model:visible="open" title="短连接" @cancel="clickCancel" @ok="upOk" cancel-text="复制" ok-text="跳转">
    <a class="a" :href="url"><h3>{{url}}</h3></a>
    </a-modal>
  </div>
  </div>
</template>

<script setup lang="ts">
import { reactive, ref } from "vue"
import { addPath} from "../uslit/res";
const open = ref(false)
const visible = ref(false)
const url = ref('');
const form = reactive({
      key: '',
      path: '',
      id:'l.lidabai.icu'
    });
const Open = (value:string) => {
  form.path=value;//将用户输入的路径赋值
  visible.value=true;
}
const handleBeforeOk = () =>{
  //上传
  addPath(form.key,form.path,form.id).then((res)=>{
    if (res!=false) {
      url.value=res; 
      open.value=true
    }
  });
  visible.value=false;
}
const handleCancel = () =>{
  visible.value=false;
}
const clickCancel = ()=>{
  navigator.clipboard.writeText(url.value).then(() => {
    console.log("ok")
    open.value=false
  })
}
const upOk = () => {
  window.location.href=url.value
}
</script>
<style>
.a{
  color: black;
  text-decoration:none;
}
.input{
  width: 60%;
}

.group{
  padding-top: 40px;
}
</style>