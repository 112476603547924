import { createRouter, createWebHistory} from 'vue-router'
import Index from '../page/IndexPage.vue'


const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'index',
      component: Index,
      meta:{
        title:"黎小白-一个短链接网站"
      }
    }
  ]
})

export default router
