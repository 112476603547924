import axios from "axios"
import { Message } from '@arco-design/web-vue';


export const addPath = (key:string,path:string,id:string) => {

    const onlineSysUrl = "https://sys.lidabai.icu/api/add";//线上后端接口
    const rootSysUrl = "http://localhost:8184/api/add";//本地后端接口
    return axios.post(onlineSysUrl,{
        "key":key,
        "idUrl":id,
        "path":path
    }
    ).then((res)=>{
        if (res.data.code==-1) {
            Message.error(res.data.msg);
            return false;
        }
        return res.data.data;
    }).catch((err)=>{
        console.log(err);
        Message.error("很抱歉,后端接口罢工了")
        return false
    })
}

