<template>
  <a-alert type="warning" @click="handleClickWarning" banner center closable >
    上传违法违规网站将承担法律责任
      </a-alert>

  <a-layout-header class="header">
    <a-tooltip content="我们是做短链接的" background-color="#165DFF">
      <img src="../assets/logo.png" alt="logo" class="logo">
     <h1 class="h">黎小白</h1>
    </a-tooltip>
  </a-layout-header>
  <a-layout-content>
    <div class="Page">
      <IndexPage/>
    </div>

  </a-layout-content>
  <a-layout-footer class="footer">

    <h3>黎大白（黎梓轩）开发ⓒ2024|桂ICP备2024024630号</h3>
    </a-layout-footer>
  <a-modal v-model:visible="open">
    <template #title>
      禁止上传违法违规内容警告
    </template>
    <div>

      尊敬的用户：
      <br>
      您好！
      <br>
      感谢您选择使用黎小白。为了确保我们网站的安全、合法和健康发展，我们特此发布本警告，使用我们的服务即表示您已同意本警告，希望您能严格遵守以下规定，禁止上传任何违法违规的内容。
      <br>
      一、禁止内容
      <br>
      违反国家法律法规的内容，包括但不限于涉及政治敏感、暴力恐怖、色情低俗、侵权盗版等；
      涉及个人隐私、商业机密、国家安全等敏感信息的内容；
      含有虚假、误导性信息的内容，误导用户或损害他人利益；
      违反社会公德、伦理道德的内容，损害社会公共利益。
      <br>
      二、责任与后果
      <br>
      用户应自行承担上传内容的合法性、真实性及完整性，确保所上传内容不违反国家法律法规和本网站的规定；
      若发现用户上传违法违规内容，本网站有权立即删除相关内容，并对用户进行警告、限制访问、封禁账号等处理；
      若因用户上传违法违规内容导致本网站遭受法律纠纷、经济损失或声誉损害，用户应承担相应的法律责任和经济赔偿责任。
      <br>
      再次感谢您的支持与合作，让我们携手共建一个合法、安全、健康的网络环境。
      <br>
      敬请遵守！
      <br>
      发表者：黎小白网站官方
      <br>
      发布日期：2024年3月31号
    </div>
  </a-modal>
</template>

<script setup lang="ts">
import IndexPage from "@/page/IndexPage.vue";
import { Modal } from '@arco-design/web-vue';
import {ref} from "vue";
const open=ref(false)
const handleClickWarning = () => {
  open.value=true
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->f
<style scoped>
.Page{
  width: 70%;
  height:200px;
  margin: 0 auto;
  padding-top: 100px;
}
.h{
  color: #165DFF;
}
.footer{
  width: 100%;
  position: absolute;
  bottom: 0;
}
.logo{
  width: 150px;
  height: 150px;
}
.header{
  padding-top: 50px;
}

</style>
